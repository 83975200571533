import React, { ReactElement } from 'react';

import { Row, Col } from 'react-bootstrap';
import Link from 'ui-kit/link/link';
import { PressReleaseCardProps } from './press-release-card.props';

import '../press-release.style.scss';
import './press-release-card.style.scss';

const PressReleaseCard = ({
    title,
    publishedDate,
    author,
    tags,
    path,
    externalLink
}: PressReleaseCardProps): ReactElement => {
    return (
        <Row className="press-release-card h-100">
            <Col>
                <div className="border-bottom h-100">
                    <div>
                        <Link
                            className="lead"
                            to={externalLink ? externalLink : path ? path : ''}
                            external={externalLink ? true : false}
                            label={title ? title : ''}
                            dataGALocation={'PressReleaseCard'}
                        />
                    </div>
                    <div className="press-release-details__info-container my-3 py-1">
                        {publishedDate && <div className="press-release-details__info">{publishedDate}</div>}
                        {author && <div className="press-release-details__info">{author}</div>}
                    </div>
                    {tags && tags?.length > 0 && (
                        <div className="press-release__tags mb-3">
                            <div className="press-release__tag">{tags[0].name}</div>
                        </div>
                    )}
                </div>
            </Col>
        </Row>
    );
};

export default PressReleaseCard;
