import React, { ReactElement } from 'react';

import { Row, Col } from 'react-bootstrap';

import '../press-release.style.scss';
import './press-release-featured.style.scss';
import { PressReleaseCardProps } from '../card/press-release-card.props';
import Link from 'ui-kit/link/link';

const PressReleaseFeatured = ({
    eyebrowText,
    title,
    publishedDate,
    author,
    tags,
    path,
    pathLabel,
    externalLink
}: PressReleaseCardProps): ReactElement => {
    return (
        <Row className="press-release-featured p-3 p-lg-5 mb-5">
            <Col className="px-2 px-lg-4">
                {eyebrowText && <div className="h5 text-uppercase text-primary">{eyebrowText}</div>}
                <h3>{title}</h3>
                <div className="press-release-details__info-container my-3 py-1">
                    {publishedDate && <div className="press-release-details__info">{publishedDate}</div>}
                    {author && <div className="press-release-details__info">{author}</div>}
                </div>
                {tags.length > 0 && (
                    <div className="press-release__tags mb-4">
                        {tags.map((tag, index) => {
                            return (
                                <React.Fragment key={index}>
                                    {<div className="press-release__tag">{tag.name}</div>}
                                </React.Fragment>
                            );
                        })}
                    </div>
                )}
                <Link
                    to={externalLink ? externalLink : path ? path : ''}
                    variant="cta-button"
                    external={externalLink ? true : false}
                    label={pathLabel ? pathLabel : ''}
                    dataGALocation={eyebrowText ? eyebrowText : ''}
                />
            </Col>
        </Row>
    );
};

export default PressReleaseFeatured;
